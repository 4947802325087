
import { isTouchScreen } from './helpers.js';

document.addEventListener('DOMContentLoaded', function () {

    if (isTouchScreen()) {
        document.body.classList.add('is_touch_screen');
    }

    /** Loading management */

    document.getElementById('page-loader').classList.add('hide');
    document.querySelector('.site-content').classList.remove('hide');

    document.querySelectorAll('img').forEach(function (img) {
        if (img.complete) {
            img.classList.add('loaded');
        } else {
            img.onload = function () {
                img.classList.add('loaded');
            };
        }
    });

    /** To avoid having the play button on video on old devices */

    const videoEl = document.querySelector('#video-container video');
    if (videoEl) {
        videoEl.addEventListener('canplay', function () {
            videoEl.play();
        });
    }


    /** Monogram animation */

    const svgElement = document.getElementById('monogram');
    const colors = ['#fbe600', '#2a63f3', '#c847bc'];
    let gap = 10;
    let counter = 0;
    if (svgElement) {

        if (document.body.id !== 'is_front_page') {
            svgElement.querySelectorAll('path').forEach(function (path) {
                path.setAttribute('fill', '#fbe600');
            });

            svgElement.addEventListener('mousemove', (event) => {
                if (counter === gap) {
                    counter = 0;
                    const randomColor = colors[Math.floor(Math.random() * colors.length)];
                    svgElement.querySelectorAll('path').forEach(function (path) {
                        path.setAttribute('fill', randomColor);
                    });
                } else {
                    counter++;
                }
            });
        }
        svgElement.addEventListener('touchmove', (event) => {
            event.preventDefault(); // Prevent default touch behavior like scrolling
            if (counter === gap) {
                counter = 0;
                const randomColor = colors[Math.floor(Math.random() * colors.length)];
                svgElement.querySelectorAll('path').forEach(function (path) {
                    path.setAttribute('fill', randomColor);
                });
            } else {
                counter++;
            }
        });

    }

    /** Menu animation */
    if (document.body.id !== 'is_front_page') {
        const menu = document.getElementById('menu');
        if (menu) {
            if (!isTouchScreen()) {
                menu.addEventListener('mouseover', (event) => {
                    menu.classList.add('open');
                });
                menu.addEventListener('mouseout', (event) => {
                    menu.classList.remove('open');
                });
            } else {
                menu.classList.add('open');
            }
        }
    }

    /** Home page animation */
    if (document.body.id === 'is_front_page') {
        const init = document.getElementById('intro-image-container');
        const hover = document.getElementById('intro-image-container_hover');
        const trigger_zone = init.querySelector('.target');
        const target_url = hover.dataset.target;

        if (!isTouchScreen()) {
            trigger_zone.addEventListener('mouseover', (event) => {
                init.style.opacity = '0';
                init.style.zIndex = '-1';
                hover.style.opacity = '1';
                hover.style.zIndex = '10';
            });
            hover.addEventListener('mouseout', (event) => {
                init.style.opacity = '1';
                init.style.zIndex = '10';
                hover.style.opacity = '0';
                hover.style.zIndex = '-1';
            });
        }

        hover.addEventListener('click', (event) => {
            if (target_url) {
                init.style.opacity = '0';
                hover.style.opacity = '0';
                document.getElementById('page-loader').classList.remove('hide');
                document.querySelector('.site-content').classList.add('hide');
                setTimeout(function () {
                    window.location = target_url;
                }, 500);
            }
        });
    }

    /** Blocks animation */
    if (document.body.id !== 'is_front_page') {

        const blocks = document.querySelectorAll('.block:not(.inanimate)');

        blocks.forEach(function (block) {

            if (isTouchScreen()) {
                block.classList.add('hover');
            } else {
                block.addEventListener('mouseover', (event) => {
                    block.classList.add('hover');
                });
                block.addEventListener('mouseout', (event) => {
                    block.classList.remove('hover');
                });
            }

        });

        const monogram_and_resourceBlock = document.getElementById('id_monogram_and_resource');
        const resourceBlock = document.getElementById('id_resource');

        if (isTouchScreen()) {
            monogram_and_resourceBlock.classList.add('hover');
        } else {
            resourceBlock.addEventListener('mouseover', (event) => {
                monogram_and_resourceBlock.classList.add('hover');
            });
            resourceBlock.addEventListener('mouseout', (event) => {
                monogram_and_resourceBlock.classList.remove('hover');
            });
        }
    }

    /** Init. sliders */
    if (document.body.id !== 'is_front_page') {

        const interval = 5000;

        document.querySelectorAll('.slider').forEach(function (slider) {

            const slidesContainer = slider.querySelector('.slides');
            const slides = slider.querySelectorAll('.slides>div');
            const slides_qty = slides.length;
            let intervalID;

            slides.forEach(function (slide, i) {
                slide.setAttribute('data-index', i);
            });

            slides[0].classList.add('active');

            if (slidesContainer) {

                let currentSlide = 0;

                function nextSlide() {
                    slider.querySelector(`.slides>div[data-index="${currentSlide}"]`).classList.remove('active');

                    if (currentSlide === (slides_qty - 1)) {
                        currentSlide = 0;
                    } else {
                        currentSlide++;
                    }

                    slider.querySelector(`.slides>div[data-index="${currentSlide}"]`).classList.add('active');

                }

                function startSlider() {
                    intervalID = setInterval(nextSlide, interval);
                }

                function pauseSlider() {
                    clearInterval(intervalID);
                }

                startSlider();

                if (isTouchScreen()) {
                    slider.addEventListener('touchstart', pauseSlider);
                    slider.addEventListener('touchend', startSlider);
                } else {
                    slider.addEventListener('mouseover', pauseSlider);
                    slider.addEventListener('mouseout', startSlider);
                }

            }
        });

    }

});
